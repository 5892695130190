import React, { useState } from 'react';
import Modal from 'react-modal';
import { login } from '../pocketbaseClient';
import { X } from 'lucide-react';

const LoginModal = ({ isOpen, onRequestClose, onLoginSuccess }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(email, password);
            onLoginSuccess();
            onRequestClose();
        } catch (error) {
            setError('Invalid email or password');
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Login Modal"
            className="p-8 bg-white  rounded-xl shadow-lg w-[440px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            overlayClassName="fixed inset-0 bg-black/50 backdrop-blur-sm"
        >
            <div className="w-full space-y-10">
                <div className="relative">
                    <button
                        onClick={onRequestClose}
                        className="absolute right-0 top-0 rounded-lg p-1 hover:bg-slate-100  transition-colors"
                    >
                        <X className="h-4 w-4" />
                    </button>
                    <h2 className="text-center text-2xl font-bold tracking-tight text-gray-900 ">
                        Program preturi Husqvarna
                    </h2>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <div className="col-span-2">
                            <input
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                placeholder="Email address"
                                className="block w-full rounded-t-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:relative focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-900 sm:text-sm/6"
                            />
                        </div>
                        <div className="-mt-px">
                            <input
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                placeholder="Password"
                                className="block w-full rounded-b-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300  placeholder:text-gray-400  focus:relative focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-900 sm:text-sm/6"
                            />
                        </div>
                    </div>



                    {error && (
                        <p className="text-sm text-red-500">{error}</p>
                    )}

                    <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-blue-950 px-3 py-1.5 text-sm font-semibold text-white hover:bg-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-900"
                    >
                        Sign in
                    </button>
                </form>

            </div>
        </Modal>
    );
};

export default LoginModal;
