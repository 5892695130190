/* eslint-disable no-redeclare */
// src/components/ProductPDFViewer.js
import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';

const fonts = [
    { fileName: 'XM-454680', fontName: 'HusqvarnaThin' },
    { fileName: 'LW-223069', fontName: 'HusqvarnaRegular' },
    { fileName: 'NP-614767', fontName: 'HusqvarnaBold' },
    { fileName: 'OQ-377382', fontName: 'HusqvarnaBoldI' },
    { fileName: 'PZ-763040', fontName: 'HusqvarnaThinI' },
    { fileName: 'XZ-549644', fontName: 'HusqvarnaRegularI' },
];

const ProductPDFViewer = ({ product }) => {
    const [pdfUrl, setPdfUrl] = useState('');
    const [labelSize, setLabelSize] = useState('small'); // Default to 'small' (eticheta mica)
    const [docInstance, setDocInstance] = useState(null);


    useEffect(() => {
        if (product) {
            generatePdf(product, labelSize);
        }
    }, [product, labelSize]);


    const generatePdf = async (product, size) => {
        let doc;

        // Configure document size based on selected label size
        if (size === 'small') {
            // Eticheta mica: 80x40mm
            doc = new jsPDF({
                orientation: 'l',
                unit: 'mm',
                format: [80, 40],
                compress: true
            });

            for (const font of fonts) {
                try {
                    doc.addFont(`font/${font.fileName}.ttf`, font.fontName, 'normal');
                } catch (error) {
                    console.error(`Error loading font ${font.fontName}:`, error);
                }
            }


            doc.addImage("images/JPG/logo-husqvarna.jpg", "JPEG", 62, 0, 13, 13);
            doc.addImage("images/PNG/background.png", "PNG", -10, 20, 100, 40);
            // Add content to the PDF
            var nume = doc.setFont('HusqvarnaBold').setFontSize(15).splitTextToSize(`${product.nume}`, 58);
            doc.setTextColor(59, 59, 58);
            doc.text(nume, 5, 7);

            if (+product.pret_nou > 0) {
                doc.setTextColor(208, 96, 21);
                var pret_nou = doc.setFont('HusqvarnaBold').setFontSize(20).splitTextToSize(`${product.pret_nou}`, 60);
                doc.text(pret_nou + ' lei', 5, 30);
                var pret_nou_width = doc.getTextWidth(pret_nou + ' Lei');
                doc.setTextColor(59, 59, 58);
                doc.setFont('HusqvarnaThin');
                doc.setFontSize(10);
                doc.text('TVA inclus', 5 + pret_nou_width, 30)
                doc.setFont('HusqvarnaBold');
                doc.setFontSize(6);

                doc.text(product.pret + ' lei TVA inclus / cod produs: ' + product.model_code, 5, 35)
                doc.line(5, 34.7, doc.getTextWidth(product.pret + ' lei TVA inclus') + 5, 34);
            } else {
                doc.setTextColor(208, 96, 21);
                var pret_nou = doc.setFont('HusqvarnaBold').setFontSize(20).splitTextToSize(`${product.pret}`, 60);
                doc.text(pret_nou + ' lei', 5, 30);
                var pret_nou_width = doc.getTextWidth(pret_nou + ' Lei');
                doc.setTextColor(59, 59, 58);
                doc.setFont('HusqvarnaThin');
                doc.setFontSize(10);
                doc.text('TVA inclus', 5 + pret_nou_width, 30)
                doc.setFont('HusqvarnaBold');
                doc.setFontSize(6);

                doc.text('Cod produs: ' + product.model_code, 5, 35)
            }




            doc.setTextColor(59, 59, 58);


            doc.setFont('HusqvarnaBold');
            doc.setFontSize(6);
            doc.text(product.descriere_e, 5, 37.5)


        } else if (size === 'large') {
            // Eticheta mare: A6
            doc = new jsPDF({
                orientation: 'l',
                unit: 'mm',
                format: 'a6',
                compress: true
            });

            for (const font of fonts) {
                try {
                    doc.addFont(`font/${font.fileName}.ttf`, font.fontName, 'normal');
                } catch (error) {
                    console.error(`Error loading font ${font.fontName}:`, error);
                }
            }


            //LOGO HUSQVARNA
            doc.addImage("images/JPG/logo-husqvarna.jpg", "JPEG", 120, 0, 20, 20);

            //BACKGROUND
            doc.addImage("images/PNG/background.png", "PNG", 0, 70, 148, 60);

            //NUME
            var nume = doc.setFont('HusqvarnaBold').setFontSize(25).splitTextToSize(`${product.nume}`, 100);
            doc.setTextColor(59, 59, 58);
            doc.text(nume, 7, 18);

            if (+product.descriere_c2 !== 0) {
                //DESCRIERE STANGA
                var descriere_stanga = doc.setFont('HusqvarnaThin').setFontSize(10).splitTextToSize(`${product.descriere_c1}`, 40);
                doc.text(descriere_stanga, 7, 37);

                //DESCRIERE STANGA
                var descriere_dreapta = doc.setFont('HusqvarnaThin').setFontSize(10).splitTextToSize(`${product.descriere_c2}`, 40);
                doc.text(descriere_dreapta, 50, 37);

            } else {
                //DESCRIERE STANGA
                var descriere_stanga = doc.setFont('HusqvarnaThin').setFontSize(10).splitTextToSize(`${product.descriere_c1}`, 105);
                doc.text(descriere_stanga, 7, 37);
            }

            var gap = 2;

            //f_n1
            var f_n1 = doc.setFont('HusqvarnaThin').setFontSize(6).splitTextToSize(`${product.f_n1}`, 30);
            doc.text(f_n1, 120, 36);
            var h_f_n1 = doc.getTextDimensions(f_n1).h;
            //f_d1
            var f_d1 = doc.setFont('HusqvarnaBold').setFontSize(9).splitTextToSize(`${product.f_d1}`, 30);
            doc.text(f_d1, 120, 36 + h_f_n1 + gap);
            var h_f_d1 = doc.getTextDimensions(f_d1).h;

            var h_f_1 = 36 + h_f_n1 + h_f_d1 + gap;

            //f_n2
            var f_n2 = doc.setFont('HusqvarnaThin').setFontSize(6).splitTextToSize(`${product.f_n2}`, 30);
            doc.text(f_n2, 120, h_f_1 + gap);
            var h_f_n2 = doc.getTextDimensions(f_n2).h + gap;
            // f_d2
            var f_d2 = doc.setFont('HusqvarnaBold').setFontSize(9).splitTextToSize(`${product.f_d2}`, 30);
            doc.text(f_d2, 120, h_f_1 + h_f_n2 + gap);
            var h_f_d2 = doc.getTextDimensions(f_d2).h;

            var h_f_2 = h_f_1 + h_f_n2 + h_f_d2 + gap;

            //f_n3
            var f_n3 = doc.setFont('HusqvarnaThin').setFontSize(6).splitTextToSize(`${product.f_n3}`, 30);
            doc.text(f_n3, 120, h_f_2 + gap);
            var h_f_n3 = doc.getTextDimensions(f_n3).h + gap;
            // f_d3
            var f_d3 = doc.setFont('HusqvarnaBold').setFontSize(9).splitTextToSize(`${product.f_d3}`, 30);
            doc.text(f_d3, 120, h_f_2 + h_f_n3 + gap);

            // // Linie F
            // doc.setDrawColor(145, 145, 144);
            // doc.line(113, 32, 113, 67);

            // //f_n4
            // var f_n4 = doc.setFont('HusqvarnaThin').setFontSize(6).splitTextToSize(`${product.f_n4}`, 16);
            // doc.text(f_n4, 116, 36);
            // var h_f_n4 = doc.getTextDimensions(f_n4).h;
            // //f_d4
            // var f_d4 = doc.setFont('HusqvarnaThin').setFontSize(12).splitTextToSize(`${product.f_d4}`, 16);
            // doc.text(f_d4, 116, 36 + h_f_n4 + 3);
            // var h_f_1 = 36 + h_f_n4 + 3;

            // //f_n5
            // var f_n5 = doc.setFont('HusqvarnaThin').setFontSize(6).splitTextToSize(`${product.f_n5}`, 16);
            // doc.text(f_n5, 116, h_f_1 + 5);
            // var h_f_n5 = doc.getTextDimensions(f_n5).h;
            // // f_d5
            // var f_d5 = doc.setFont('HusqvarnaThin').setFontSize(12).splitTextToSize(`${product.f_d5}`, 16);
            // doc.text(f_d5, 116, h_f_1 + 5 + h_f_n5 + 3);
            // var h_f_2 = h_f_1 + 5 + h_f_n5 + 3;

            // //f_n6
            // var f_n6 = doc.setFont('HusqvarnaThin').setFontSize(6).splitTextToSize(`${product.f_n6}`, 16);
            // doc.text(f_n6, 116, h_f_2 + 5);
            // var h_f_n6 = doc.getTextDimensions(f_n6).h;
            // // f_d6
            // var f_d6 = doc.setFont('HusqvarnaThin').setFontSize(12).splitTextToSize(`${product.f_d6}`, 16);
            // doc.text(f_d6, 116, h_f_2 + 5 + h_f_n6 + 3);

            if (+product.pret_nou > 0) {
                doc.setTextColor(208, 96, 21);
                var pret_nou = doc.setFont('HusqvarnaBold').setFontSize(30).splitTextToSize(`${product.pret_nou}`, 60);
                doc.text(pret_nou + ' lei', 7, 87);
                var pret_nou_width = doc.getTextWidth(pret_nou + ' Lei');
                doc.setTextColor(59, 59, 58);
                doc.setFont('HusqvarnaThin');
                doc.setFontSize(15);
                doc.text('TVA inclus', 7 + pret_nou_width, 87)
                doc.setFont('HusqvarnaBold');
                doc.setFontSize(6);

                doc.text(product.pret + ' lei TVA inclus / cod produs: ' + product.model_code, 7, 92)
                doc.setDrawColor(208, 96, 21);
                doc.line(7, 92, doc.getTextWidth(product.pret + ' lei TVA inclus') + 7, 91);

                //OFERTA
                doc.setDrawColor(208, 96, 21);
                doc.setFillColor(208, 96, 21);
                doc.setTextColor(255, 255, 255);
                doc.setFont('HusqvarnaBold');
                doc.setFontSize(10);
                doc.rect(7, 0, 15, 5, "F");
                doc.text("OFERTĂ", 14.5, 3.5, 'center');
                doc.setTextColor(59, 59, 58);


            } else {
                doc.setTextColor(208, 96, 21);
                var pret_nou = doc.setFont('HusqvarnaBold').setFontSize(30).splitTextToSize(`${product.pret}`, 60);
                doc.text(pret_nou + ' lei', 7, 87);
                var pret_nou_width = doc.getTextWidth(pret_nou + ' Lei');
                doc.setTextColor(59, 59, 58);
                doc.setFont('HusqvarnaThin');
                doc.setFontSize(10);
                doc.text('TVA inclus', 7 + pret_nou_width, 87)
                doc.setFont('HusqvarnaBold');
                doc.setFontSize(6);

                doc.text('Cod produs: ' + product.model_code, 7, 92)
            }

            doc.setFont('HusqvarnaBold');
            doc.setFontSize(6);
            doc.text(product.descriere_e, 7, 95)

            doc.setDrawColor(59, 59, 58);

            //COLT STANGA SUS
            doc.line(0, 0, 5, 0);
            doc.line(0, 0, 0, 5);

            //COLT STANGA JOS
            doc.line(0, 105, 5, 105);
            doc.line(0, 100, 0, 105);

            //COLT DREAPTA SUS
            doc.line(143, 0, 148, 0);
            doc.line(148, 0, 148, 5);

            //COLT DREAPTA JOS
            doc.line(143, 105, 148, 105);
            doc.line(148, 100, 148, 105);

        }



        // Generate the PDF as a Data URL string
        const pdfDataUrl = doc.output('dataurlstring');
        setPdfUrl(pdfDataUrl);
        setDocInstance(doc);
    };

    const handleLabelSizeChange = (event) => {
        setLabelSize(event.target.value);
    };

    const handleDownloadPdf = () => {
        if (docInstance && product) {
            const sizeSuffix = labelSize === 'small' ? '8x4' : 'A6';
            const fileName = `${product.nume}_${sizeSuffix}.pdf`;
            docInstance.save(fileName);
        }
    };

    return (
        <div className="p-4 sticky top-0">
            <div className="mb-4 flex items-center w-full justify-between">
                <div>
                    <label className="mr-2">Selecteaza eticheta:</label>
                    <select value={labelSize} onChange={handleLabelSizeChange} className="border p-2">
                        <option value="small">Eticheta Mica (80x40mm)</option>
                        <option value="large">Eticheta Mare (A6)</option>
                    </select>
                </div>
                <div>
                    <button
                        onClick={handleDownloadPdf}
                        className=" bg-green-500 text-white px-4 py-2 rounded"
                    >
                        Download PDF
                    </button>

                </div>
            </div>
            {pdfUrl ? (
                <iframe src={pdfUrl} title="PDF Preview" style={{ width: '100%', height: '600px' }} />
            ) : (
                <p>Va rugam selectati un produs.</p>
            )}
        </div>
    );
};

export default ProductPDFViewer;

