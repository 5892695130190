import PocketBase from 'pocketbase';

const pb = new PocketBase('https://dbp.bdesk.ro'); // Replace with your PocketBase URL

export const login = async (email, password) => {
    return await pb.collection('users').authWithPassword(email, password);
};

export const logout = () => {
    pb.authStore.clear();
};

export const isAuthenticated = () => pb.authStore.isValid;

export default pb;

